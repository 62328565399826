.footer {
  background-color: #0e1122;
  color: white;
  padding: 0 0 20px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (min-width: 768px) {
        margin-top: 50px;
      }
    .col {
      padding: 0 15px;
      flex: 1 0 100%;


      @media (min-width: 768px) {
        flex: 1 0 25%;
      }
      &.col-md-3{
        margin-top: 40px;
      }
      &.col-md {
        flex: 1 0 100%;
        text-align: center;
     
        @media (min-width: 768px) {
          flex: 1 0 auto;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        margin-right: 10px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0.5rem;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      h5 {
        color: white;
        margin-bottom: 1rem;

        a {
          color: white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .social-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 1rem;

        @media (min-width: 768px) {
          justify-content: flex-start;
        }

        a {
          img {
            width: 24px;
            height: 24px;
            transition: transform 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .logo {
      width: 300px;  /* Set the width */
      height: 100px; /* Set the height */
    }
    
    .logo path,
    .logo rect,
    .logo circle,
    .logo polygon {
      fill: #FFFFFF; /* Set the fill color to white */
    }
  &.copyrights {
      border-top: 1px solid #444;
      padding-top: 1rem;
      margin-top: 1rem;


      .col.c1:nth-child(1) {
        display: flex;
        justify-content: end;
        order: 1;
        @media (max-width: 767px) {
          order: 3;
          justify-content: center;
        }
      }

      .col.c2:nth-child(2) {
        order: 2;
      }

      .col.c3:nth-child(3) {
        order: 3;
        @media (max-width: 767px) {
          order: 1;
        }
      }

      .col-md {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        @media (min-width: 768px) {
          justify-content: flex-start;
          margin-bottom: 0;
        }

        &:last-child {
          justify-content: flex-end;

          @media (min-width: 768px) {
            justify-content: center;
          }
        }

        a {
          color: white;
          text-decoration: none;
          display: flex;
          align-items: center;

          img {
            margin-right: 0.5rem;
          }

          &:hover {
            text-decoration: underline;
          }
         
        }
      }
    }
  }

}
