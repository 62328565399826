.backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit:contain;
    z-index: -1;
    background-color: black;

    
    .content {
      position: relative;
      z-index: 1;
      color: white;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
  }