.servicesSection {
  padding: 50px;

  .introSection {
    text-align: center;

    h3 {
      font-size: 40px ;

      @media (max-width: 991px) {
        font-size: 25px;
      }
    }
  }

  .serviceDetailSection {
    margin-top: 50px;

    .serviceDetail {
      display: flex;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      margin-bottom: 50px;
      opacity: 0;
      /* Initial state for fade-in animation */
      transform: translateY(50px);
      /* Initial position for slide-in */
      transition: opacity 0.8s ease-out, transform 0.8s ease-out;

      &.visible {
        opacity: 1;
        transform: translateY(0);
        /* Slide to original position */
      }

      &.left {
        flex-direction: row;
      }

      &.right {
        flex-direction: row-reverse;
      }

      .serviceImage {
        width: 40%;
        height: 300px; 
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        opacity: 0;
        /* Initial state for pop-up */
        transform: scale(0.8);
        /* Initial scale for pop-up */
        transition: opacity 0.8s ease-out, transform 0.8s ease-out;

        &.visible {
          opacity: 1;
          transform: scale(1);
          /* Pop-up effect */
        }
      }

      .serviceContent {
        margin-left: 20px;
        width: 55%;
        text-align: left;
        opacity: 0;
        /* Initial state for text */
        transform: translateX(-50px);
        /* Initial position for text slide-in */
        transition: opacity 0.8s ease-out, transform 0.8s ease-out;

        &.visible {
          opacity: 1;
          transform: translateX(0);
          /* Slide to original position */
        }

        &.right {
          transform: translateX(50px);

          @media (max-width: 960px) {
            transform: translateX(0px);
          }

          /* Slide-in from the right */
        }

        h3 {
          font-size: 2rem;
          color: #fff;
        }

        p {
          font-size: 1rem;
          color: #8F9BB7;
          margin-top: 10px;
        }

        .serviceButtons {
          margin-top: 20px;

          button {
            padding: 10px 20px;
            margin: 10px 5px;
            background-color: #000;
            color: #fff;
            border-radius: 5px;
            border-color: #fff;
            cursor: pointer;

            &:hover {
              background-color: #8B63E1;
            }
          }
        }
      }
    }
  }

  /* Responsive Adjustments */
  @media (max-width: 960px) {
    .serviceDetailSection .serviceDetail {
      flex-direction: column;
      align-items: center;
      text-align: center;

      &.left {
        flex-direction: column;
      }

      &.right {
        flex-direction: column;
      }

      .serviceImage {
        width: 80%;
        height: 250px;
        margin-bottom: 20px;
        transform: scale(0.8);
      }

      .serviceContent {
        width: 100%;
        margin-left: 0;
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 768px) {
    .serviceDetailSection .serviceDetail .serviceImage {
      width: 100%;
      height: 200px;
    }

    .serviceContent {
      padding: 0 10px;
    }
  }

  @media (max-width: 480px) {
    padding: 20px;

    .serviceDetailSection .serviceDetail .serviceImage {
      width: 100%;
      height: 150px;
    }

    .serviceDetailSection .serviceDetail .serviceContent h3 {
      font-size: 1.5rem;
    }

    .serviceDetailSection .serviceDetail .serviceContent p {
      font-size: 0.9rem;
    }
  }

  .backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}