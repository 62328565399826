/* Add font-face for Montserrat */
@font-face {
  font-family: 'Montserrat';
  src:url('../fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src:url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* Ensure Comic Sans is available */
@font-face {
  font-family: 'Comic Sans MS';
  src:url('../fonts/Comic_Neue/ComicNeue-Regular.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Montserrat-Bold'; /* Use Montserrat Bold for body text */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat-Bold';
  font-weight: bold;
}

/* Any other global styles remain the same */
@media (min-width: 1400px) {
  body .container{
    max-width: 1140px;
  }
}

* {
  font-family: 'Montserrat-Bold'; 
}

.text-right {
  text-align: right;
}

.gradient-text {
  background: #8B63E1;
  // background: linear-gradient(91.16deg, #9795FF 1.74%, #FFFFFF 43.71%, #FFFFFF 61.62%, #BE9FFF 102.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header a {
  color: #fff;
  margin: 0 8px;
}

.header .navbar-nav .nav-link.active, .navbar-nav .nav-link.show, .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
  color: #9795FF;
}

.outline-light {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid #fff;
  padding: 10px 15px;
}

.footer {
  background-color: #0e1022;
  padding: 5rem 0 1rem;
}

.footer p {
  color: #fff;
}

.footer h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-top: 8px;
  font-size: 16px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer .social-icons {
  gap: 15px;
}

.footer .cookies-privacy .privacy-wrapper {
  display: flex;
  padding: 15px 15px;
  gap: 15px;
  background: #0e1122;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
  border-top: 1px solid;
}

.footer .cookies-privacy button {
  white-space: nowrap;
  height: 40px;
  border-color: #6440D4;
  color: #fff;
}

.footer .cookies-privacy .accept {
  background-color: #6440D4;
}

.footer .cookies-privacy .reject {
  background-color: transparent;
  border: 1px solid #fff;
}

.logo {
  width: 180px;
}

.navbar-brand {
  display: none;
}

#Privacy {
  padding: 3rem 0;
}

@media (max-width: 1200px) {
  .d-n-m {
    display: none;
  }

  .header .navbar-brand {
    display: block;
  }

  .header container {
    justify-content: end;
  }

  .header button {
    background: #fff;
  }

  .header .navbar .col-2 {
    display: none;
  }

  .header .navbar .col-8 {
    width: 100% !important;
  }

  .header .navbar-expand-lg .navbar-collapse {
    justify-content: end !important;
  }

  .header .navbar-nav {
    background-color: #0e1022;
  }
}

@media (max-width: 480px) {
  .footer .cookies-privacy .privacy-wrapper {
    display: block;
  }

  .footer .cookies-privacy .accept {
    margin-right: 20px;
  }
}
