.technologiesSection {
  .content {
    text-align: center;
    padding: 2rem;
    max-width: 90%;
    margin: auto;

    @media (max-width: 780px) {
      padding: 1rem;
    }

    .techIcons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: auto;
      padding: 1rem 0;
    }

    .techIcon {
      font-size: 4rem;
      padding: 1rem;
      display: flex;
      transition: transform 0.3s ease-in-out; /* Smooth transition effect */

      img {
        width: 100%; /* Ensure the image scales correctly */
        height: auto;
        transition: transform 0.3s ease-in-out; /* Apply smooth transition to the image */
      }

      &:hover {
        transform: scale(1.1); /* Scale up the icon a bit on hover */

        img {
          transform: scale(1.1); /* Scale up the image as well */
        }
      }
    }
  }
}
