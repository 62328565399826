// Standard spacing variables
$section-padding: 5rem 0;
$section-padding-mobile: 3rem 0;
$heading-font-size: 40px;
$heading-font-size-mobile: 25px;
$text-color: #fff;
$subheading-font-size: 22px;
$text-font-size: 16px;
$text-color-muted: #8F9BB7;

.banner {
  padding: 8rem 0 2rem;

  h3 {
    font-size: 105px;

    @media (max-width: 991px) {
      font-size: 60px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    padding: 0 15%;
  }

  .btn-consult {
    background-color: #000;
    border-radius: 50px;
    border-color: #fff;
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;

    &:hover {
      background-color: #8B63E1;
      color: #fff;
    }
  }
}

.sets-us-apart,
.customers-visit,
.about-us,
.testimonials {
  padding: $section-padding;
  position: relative;
  z-index: 2;

  h3 {
    font-size: $heading-font-size;
    font-weight: 300;

    @media (max-width: 991px) {
      font-size: $heading-font-size-mobile;
    }
  }

  .font-weight-light {
    font-weight: 300;
  }

  .container {
    position: relative;
  }

  @media (max-width: 767px) {
    padding: $section-padding-mobile;
  }
}

.sets-us-apart {
 
  .trans-col {
    h4 {
      margin: 20px 0 10px 0;
      font-size: $subheading-font-size;
      color: $text-color;
    }

    p {
      color: $text-color-muted;
    }
  }
}

.customers-visit {
  border-top: 1px solid $text-color-muted;
  border-bottom: 1px solid $text-color-muted;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .circular-progress-container,
    .star-rating-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      p {
        text-align: center;
        margin-top: 1rem;
        font-size: $text-font-size;
        color: $text-color-muted;

        @media (max-width: 768px) {
          font-size: 0.9rem;
        }

        @media (max-width: 480px) {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.about-us {
  h3{
    padding-left: 1rem;
  }
  button{
    margin-left: 1rem;
  }
  .row {
    @media(max-width:991px) {
      align-items: center;
      text-align: center;
      flex-direction: column;
    }
    .col-md-6{
      @media(max-width:991px) {
        width:100%
      }
    }
  }

  button {
    padding: 1rem;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    border-color: #fff;

    &:hover {
      background-color: #8B63E1;
    }
  }

  p {
    color: $text-color;
  }
}

.testimonials {
  background-image: url('../img/quotas-bg.png');
  background-repeat: no-repeat;
  background-position: right;

  .trans-col {
    margin-bottom: 15px;

    .name {
      font-weight: 500;
    }

    .para {
      font-size: 14px;
    }

    .maintxt {
      color: #dddddd;
    }

    .col-wrap {
      background: #15151566;
      padding: 20px;
      text-align: left;
      color: $text-color;
      border-radius: 20px;
      box-shadow: -0.3px -0.3px 0px 0.2px rgba(255, 255, 255, 1);
      position: relative;

      &::after {
        position: absolute;
        right: 15px;
        top: 15px;
        content: '';
        background-image: url('../img/quotas.png');
        width: 45px;
        height: 33px;
      }
    }
  }
}

.trusted-by {
  margin-top: 3rem;
  padding: 3rem 3rem;

  h3 {
    font-size: 40px;

    @media(max-width:991px) {
      font-size: $heading-font-size-mobile;
    }
  }

  position: relative;
  width: 100%;

  @media(max-width:767px) {
    position: relative;
    margin: 0;
  }

  .equal-spc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;

    img {
      max-width: 120px;
    }

    @media(max-width:991px) {
      gap: 5%;

      img {
        max-width: 80px;
      }
    }

    @media(max-width:767px) {
      flex-direction: column;
      gap: 20px;

      img {
        max-width: 120px;
      }
    }
  }
}

.bulbVideo,
.teamVideo,
.supportVideo {
  width: 60%;
}

.insights {
  display: flex;
  flex-direction: column;
  color: $text-color;
  text-align: center;

  .percentVideo,
  .starVideo,
  .graphVideo {
    width: 60%;
    margin: auto;
  }
}


.video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
}

.video-popup-content {
  position: relative;
  width: 100%;
  height: 80vh;
  max-width: 1200px;
  /* Set max-width as per your needs */
  max-height: 90vh;
  background: #000;
  padding: 10px;
  box-sizing: border-box;
}

.video-popup-content iframe {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  /* Optional: adds rounded corners */
}

.video-popup-overlay::after {
  content: 'Click anywhere to close';
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
}

#Expertise {
  button {
    padding: 1rem;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    border-color: #fff;

    &:hover {
      background-color: #8B63E1;
    }
  }
}