.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modalContainer {
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(21, 21, 21, 0.9); /* Slightly darker for better contrast */
  background-image: url('./../../assets/img/form-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  border-radius: 0; /* Remove border radius for full screen */
  box-shadow: none; /* Remove box shadow */
  position: relative;
  z-index: 1001;
  color: #fff;
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.modalHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .modalTitle {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }
}

.modalBody {
  padding: 15px;

  .trans-col {
    padding: 30px;
    text-align: left;
    border-radius: 20px;
    box-shadow: -0.3px -0.3px 0px 0.2px rgba(255, 255, 255, 1);
    color: #fff;

    p {
      color: #fff;
    }

    h3 {
      font-size: 48px;
      color: #fff;
    }
  }

  img {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .form-label {
    margin-top: 10px;
    color: #fff;
    font-size: 14px;
  }

  .form-check {
    margin-top: 10px;

    label {
      font-size: 14px;
      color: #fff;
    }
  }

  .input-group {
    margin-bottom: 1rem;

    .form-control {
      border: none;
      padding: 0.7rem 0.75rem;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;

      &:focus,
      &:active,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

    .input-group-text {
      background-color: #fff;
      color: #ACB4C3;
      padding: 0 10px;
      border-radius: 5px 0 0 5px;
    }
  }

  .btn-primary {
    background-color: #6440D4;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;

    &:hover {
      background-color: #5a37c4;
    }
  }
}

.contact-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .btn {
    background-color: #6440D4;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;

    &:hover {
      background-color: #5a37c4;
    }
  }
}
