.privacy-policy-page {
  padding: 100px 0;
  /* Set the background color to black */

  .container {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #000;
    border: 1px solid #444; /* Dark gray border to blend with the black background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Light shadow for subtle contrast */

    .page-title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      text-align: center;
      color: #fff; /* White text for title */
    }

    .update-date {
      text-align: center;
      font-size: 1rem;
      color: #bbb; /* Light gray for the update date */
      margin-bottom: 40px;
    }

    .intro-text {
      font-size: 1.2rem;
      margin-bottom: 20px;
      text-align: justify;
      color: #ddd; /* Light gray for intro text */
    }

    h2, h3, h4 {
      margin-top: 30px;
      margin-bottom: 10px;
      color: #fff; /* White for headings */
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 15px;
      text-align: justify;
      color: #ccc; /* Light gray for paragraphs */
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
      color: #ccc; /* Light gray for list items */

      li {
        margin-bottom: 10px;
        
        p {
          margin: 0;
        }
      }
    }

    a {
      color: #1e90ff; /* Dodger blue for links to stand out */
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
