.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Container for all header elements */
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }

  /* Logo Container */
  .logoContainer {
    height: 35px;
    width: 200px;
    overflow: hidden;

    .animatedLogo {
      /* Target all elements inside the SVG */
      path,
      rect,
      circle,
      polygon,
      polyline {
        fill: #FFFFFF; /* Initial fill color */
        clip-path: polygon(0 0, 0 100%, 0 100%, 0 0); /* Start with no visible area */
        animation: wipeFill 5s linear forwards; /* Adjust timing and easing as needed */
      }
    }

    @keyframes wipeFill {
      0% {
        clip-path: polygon(0 0, 0 100%, 0 100%, 0 0); /* Hidden at the start */
      }
      100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Fully visible and remains visible */
      }
    }
  }

  /* Navigation */
  .navbar {
    flex: 1;
    display: flex;
    justify-content: center;

    .navList {
      list-style: none;
      display: flex;
      gap: 20px;
      padding: 0;
      margin: 0;
      .mobileViewOnly {
        display: none;
      }
      .navItem {
        .navLink {
          font-size: 1.25rem;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          padding: 10px;
          transition: color 0.3s ease;
          cursor: pointer;

          &:hover {
            color: #8B63E1; /* Color change on hover */
          }
        }
      }
    }
  }

  /* Hamburger Menu */
  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 101;

    span {
      width: 100%;
      height: 3px;
      background: #fff;
      border-radius: 5px;
      transition: all 0.3s ease;
    }

    &.active span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }

    &.active span:nth-child(2) {
      opacity: 0;
    }

    &.active span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }

  /* Consult Button */
  .consultButtonContainer {
    .consultButton {
      background-color: transparent;
      color: #fff;
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 600;
      border: 2px solid #fff;
      padding: 10px 20px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      transition: background-color 0.3s ease, color 0.3s ease;

      img {
        margin-left: 10px;
        max-height: 20px;
      }

      &:hover {
        background-color: #8B63E1;
        color: #fff;
      }
    }
  }

  /* Responsive Adjustments */
  @media (max-width: 960px) {
    .navbar {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;

      &.active {
        transform: translateX(0);
      }

      .navList {
        flex-direction: column;
        gap: 15px;
        align-items: center;
        .mobileViewOnly {
          display: block;
        }
        .navItem {
          .navLink {
            font-size: 1.25rem;
            padding: 10px 0;
          }
        }
      }
    }

    .hamburger {
      display: flex;
    }

    .consultButtonContainer {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 0 15px;
    }

    .logoContainer {
      height: 30px;
      width: 150px;

      .animatedLogo {
        max-height: 100%;
      }
    }

    .navbar {
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;

      .navList {
        gap: 10px;
        width: 100%;
        padding: 0 10px;

        .navItem {
          width: 100%;
          text-align: center;

          .navLink {
            font-size: 1.15rem;
            padding: 8px 0;
          }
        }
      }
    }

    .consultButtonContainer {
      display: none;
    }
  }
}
