.verticalSlider {
    position: fixed;
    top: 35vh;
    right: 2vw;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    width: 45px;
    height: 200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
    .sliderTab {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      cursor: pointer;
      position: relative;
      outline: none; /* Remove focus outline */
      user-select: none; /* Prevent text selection and cursor from appearing */
  
      .tabLabel {
        font-size: 16px;
        position: absolute;
        font-weight: bold;
        left:-120px; /* Adjusted to provide space for the white label */
        white-space: nowrap;
        opacity: 0; /* Hidden by default */
        transition: opacity 0.3s ease;
        color: #8B63E1; /* White color for the labels */
        pointer-events: none; /* Prevent the label from blocking hover events */
      }
  
      .dot {
        width: 10px;
        height: 10px;
        background-color: #000;
        border: 2px solid #000;
        border-radius: 50%;
        position: relative;
        transition: transform 0.3s ease, border-width 0.3s ease, width 0.3s ease, height 0.3s ease;
        
        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: #000;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
  
        &:hover {
          transform: scale(1.3);
          .tabLabel {
            opacity: 1; /* Show label when active */
          }
        }
      }
  
   
  
      &.active .dot {
        width: 24px;
        height: 24px;
        border-width: 2px;
        background-color: #fff; /* Background white to match the image */
  
        &:before {
          background-color: #000; /* Inner dot remains black */
          width: 12px;
          height: 12px;
        }
      }
  
      &:hover .tabLabel {
        opacity: 1; /* Show label when active */
      }
    }
  
    @media (max-width: 780px) {
      visibility: hidden;
      display: none;
    }
  }
  